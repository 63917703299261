<template>
  <CRow>

    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('inventoryTrackingFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="dark" square
                       size="sm"
                       @click="print"
              >
                Fatura Yazdırmayı Durdur/Başlat
              </CButton>&nbsp;
              <CButton color="primary" square
                       size="sm"
                       @click="getExcel"
              >
                Excel'e Aktar
              </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="inventoryTrackings"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterInventoryTrackings"
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      :show="openModalConfirm" 
      :title="modalTitle" 
      :openModalEvent="openModalConfirmEvent"
      :confirm="activeOrPassiveInvoicePrint"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterInventoryTrackings"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import ConfirmModal from '../components/confirmModal'
import { prepareFilterParams } from "@/helpers/custom"
import moment from "moment";

export default {
  name: 'InventoryTracking',
  components: { FilterSidebar, ConfirmModal },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'inventoryTrackingFilter',
      filterParams: {},
      activePage: 1,
      fields: [
        {key: 'prodName', label: 'Ürün Adı', _style: 'font-size:12px'},
        {key: 'typeName', label: 'Ürün Tipi', _style: 'font-size:12px'},
        {key: 'code', label: 'SKU Kodu ', _style: 'font-size:12px'},
        {key: 'kaft', label: 'Kaft', _style: 'font-size:12px'},
        {key: 'kaftReserved', label: 'Kaft Reserved', _style: 'font-size:12px'},
        {key: 'kaftPrepare', label: 'Kaft Prepare', _style: 'font-size:12px'},
        {key: 'orderChange', label: 'Order Change', _style: 'font-size:12px'},
        {key: 'virtualStock', label: 'Virtual Stock', _style: 'font-size:12px'},
        {key: 'total', label: 'Toplam', _style: 'font-size:12px'}
      ],
      filters: [
        {
          type: 'string',
          dataIndex: 'prodName',
          field: 'prodName'
        }, {
          type: 'string',
          dataIndex: 'typeName',
          field: 'typeName'
        }, {
          type: 'string',
          dataIndex: 'code',
          field: 'code'
        }, {
          type: 'integer',
          dataIndex: 'total',
          field: 'total',
          comparison:'eq'
        }
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    reloadParams () {
      return [
        this.activePage
      ]
    },
    inventoryTrackings(){
      return this.$store.getters.inventoryTrackings
    },
    loading(){
      return this.$store.getters.inventoryLoading
    }
  },
  methods: {
    openModalConfirmEvent(actionType, title, desc, data){
      this.openModalConfirm = !this.openModalConfirm
      this.actionType = actionType ? actionType : this.actionType
      this.modalDesc = desc
      this.modalTitle = title
      this.modalProps = data
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item
    },

    async print(){
      await this.$store.dispatch('checkInvoicePrintStatus')

      if(this.$store.getters.inventoryStatus.success){
        this.openModalConfirmEvent('print', 'Onay', 'Fatura Yazmayı Duraklatmak İstediğine Emin misin ?', true)
      }else{
        this.openModalConfirmEvent('print', 'Onay', 'Fatura Yazmayı Başlatmak İstediğine Emin misin ?', false)
      }
    },

    async activeOrPassiveInvoicePrint(item, action) {
      let params = {
        "invoicePrintFlag": item
      }
      await this.$store.dispatch('inventoryTracking_print', params)
      if(this.$store.getters.inventoryStatus.success){
        this.openModalConfirmEvent()
        this.filterInventoryTrackings(this.filterParams)
      }
    },
    
    async getExcel() {
      let params = {
        responseType: 'blob'
      }
      await this.$store.dispatch('inventoryTracking_excel', params);
      if(this.$store.getters.inventoryStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'StokTakip_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },

    async filterInventoryTrackings(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('inventoryTracking_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  mounted() {
    this.filterInventoryTrackings(this.filterParams);
  }
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
